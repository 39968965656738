@use "../../../react-components/styles/theme.scss";

:local(.buttonContainer) {
  width: 100%;
  @media (max-width: theme.$breakpoint-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

:local(.optionsContainer) {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: theme.$breakpoint-lg) {
    flex-direction: column;
    align-items: center;
  }

  button {
    margin-right: 25px;
    @media (max-width: theme.$breakpoint-lg) {
      margin-right: 0;
    }
  }
}
